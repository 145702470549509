"use strict";
// TODO: Fix TS errors.
// @ts-nocheck
var Experiments = /** @class */ (function () {
    function Experiments() {
    }
    Experiments.prototype.trigger = function (key) {
        window.dataLayer.push({
            event: "cell-experiments.".concat(key),
        });
    };
    return Experiments;
}());
window.CellExperiments = new Experiments();
